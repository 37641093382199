
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配件列表</el-breadcrumb-item>
      <el-breadcrumb-item>批次列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click='opentan()'>添加信息</el-button>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="pur_batch_no"
          label="批次编码"
        >
        </el-table-column>
        <el-table-column
          prop="supplier_name"
          label="供应商"
        >
        </el-table-column>
        <el-table-column
          prop="brands_name"
          label="品牌"
        >
        </el-table-column>
        <el-table-column
          prop="parts_name"
          label="配件名"
        >
        </el-table-column>
        <el-table-column
          prop="pur_unit_price"
          label="采购单价"
        >
        </el-table-column>
        <el-table-column
          prop="pur_count"
          label="采购数量"
        >
        </el-table-column>
        <el-table-column
          prop="pur_total_price"
          label="采购总价"
        >
        </el-table-column>
        <el-table-column
          prop="sale_price"
          label="经销价"
        >
        </el-table-column>
        <el-table-column
          prop="pur_created_at"
          label="采购日期"
        >
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="120px" :rules="drules">
        <el-form-item label="批次编码" prop="name">
          <el-input v-model="dform.pur_batch_no"></el-input>
        </el-form-item>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="供应商">
              <el-select
                v-model="dform.supplier_id"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="(query)=>{remoteMethod(query,'供应商')}"
                :loading="loading">
                <el-option
                  v-for="item in suppliedata"
                  :key="item.id"
                  :label="item.supplier"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌">
              <el-select
                v-model="dform.brand_id"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="(query)=>{remoteMethod(query,'品牌')}"
                :loading="loading">
                <el-option
                  v-for="item in branddata"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="选择配件">
              <el-select
                v-model="dform.parts_id"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="(query)=>{remoteMethod(query,'配件')}"
                :loading="loading">
                <el-option
                  v-for="item in partsdata"
                  :key="item.id"
                  :label="item.parts_name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="采购单价">
          <el-input v-model="dform.pur_unit_price"></el-input>
        </el-form-item>
        <el-form-item label="采购数量">
          <el-input v-model="dform.pur_count"></el-input>
        </el-form-item>
        <el-form-item label="采购总价">
          <el-input v-model="dform.pur_total_price"></el-input>
        </el-form-item>
        <el-form-item label="经销价">
          <el-input v-model="dform.sale_price"></el-input>
        </el-form-item>
        <el-form-item label="采购日期">
          <el-date-picker
            v-model="dform.pur_created_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import {
  AddBatch,
  DelBatch,
  EditBatch, GetBraandsList,
  GetBatchList,
  GetOneBatch, GetPartsList, GetSupplierList
} from '../../../api/vehicle/autoconfigure'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      loading: false,
      dialogVisible: false, // 弹窗控制
      dform: {
        pur_batch_no: '', // 批次名称
        parts_id: '', // 配件id
        supplier_id: '', // 供应商id
        brand_id: '', // 品牌id
        pur_unit_price: '', // 采购单价
        pur_count: '', // 采购数量
        pur_total_price: '', // 采购总价
        sale_price: '', // 经销价
        pur_created_at: '' // 采购日期

      },
      // 选项
      optionsdata: [],
      // 弹窗
      // 按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        e_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: [],
      // 配件列表
      partsdata: [],
      // 品牌列表
      branddata: [],
      // 供应商列表
      suppliedata: []
    }
  },
  mounted() {
    this.getbatchlistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getbatchlistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getbatchlistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      this.onebatchfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delbatchfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.getbrandlistfun()
    },
    remoteMethod(query, text) {
      if (query !== '') {
        this.loading = true
        switch (text) {
          case '配件':
            this.getpartslistfun(query) // 方法
            break
          case '品牌':
            this.getbrandlistfun(query)
            break
          case '供应商':
            this.getsupplierlistfun(query)
            break
        }
      } else {
        this.suppliedata = []
        this.branddata = []
        this.partsdata = []
      }
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addbatchfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editbatchfun(this.dform)
            }
          })
          console.log(type)
          break
      }
      // console.log(this.dform)
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
      this.loading =false
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取品牌
    async getbrandlistfun(key) {
      const { data } = await GetBraandsList({ key })
      this.branddata = data.data.list
      this.loading = false
    },
    // 获取供应商
    async getsupplierlistfun(key) {
      const { data } = await GetSupplierList({ key })
      this.suppliedata = data.data.Supplier_Info
      this.loading = false
    },
    // 获取配件
    async getpartslistfun(key) {
      const { data } = await GetPartsList({ key })
      this.partsdata = data.data.list
      this.loading = false
    },
    // 获取批次
    async getbatchlistfun() {
      const { data } = await GetBatchList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加批次
    async addbatchfun(v) {
      const { data } = await AddBatch(v)
      this.getanpostfun(data, '添加', this.getbatchlistfun())
    },
    // 删除批次
    async delbatchfun(id) {
      const { data } = await DelBatch({ id })
      this.getanpostfun(data, '删除', this.getbatchlistfun())
    },
    // 修改
    async editbatchfun(ev) {
      const { data } = await EditBatch(ev)
      this.getanpostfun(data, '修改', this.getbatchlistfun())
    },
    // 查看
    async onebatchfun(id) {
      const { data } = await GetOneBatch({ id })
      this.dform = data.data.batch
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
